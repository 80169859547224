.sidebar {

    .container {
        background: #0268a6;
        padding: rem-calc(30 50);
        border-radius: 25px;
    }

    h3 {
        font-size: rem-calc(18);
        line-height: 1.2;
        margin-bottom: rem-calc(10);
        font-weight: $global-weight-bold;
    }

    p {
        font-size: rem-calc(14);
    }

    h3, p {
        text-align: center;
    }

    form {

        .button {
            width: 100%;
            color: $white;
            font-weight: $global-weight-bold;
            padding: rem-calc(15) 0;
            text-align: center;
            margin: 0;
            font-size: rem-calc(17);
            background-color: #00b194;
            border-radius: 10px;
            &:hover {
                color: $white;
            }
        }

        label {
            color: $white;
            font-size: rem-calc(20);
            font-weight: $global-weight-bold;
            line-height: 1.2;
            margin-bottom: rem-calc(5);
            display: block;
        }

        select {
            border-radius: 10px;
            padding: rem-calc(10 30 10 10);
        }

        .input-group {
            margin-bottom: rem-calc(25);
            &:last-child {
                margin-bottom: 0;
            }
        }

        input::placeholder, textarea::placeholder {
            color: $dark-gray;
        }

        .input-group {
            display: block;
        }

        .alert-box.alert {
            padding: rem-calc(5) rem-calc(10);
            border: 2px solid #cc4b37;
            margin: rem-calc(15 0 0);
        }

        .alert-box.success {
            text-align: center;
            padding: rem-calc(10) rem-calc(15);
            margin: auto;
        }

        [type='text'],
        [type='password'],
        [type='date'],
        [type='datetime'],
        [type='datetime-local'],
        [type='month'],
        [type='week'],
        [type='email'],
        [type='number'],
        [type='search'],
        [type='tel'],
        [type='time'],
        [type='url'],
        [type='color'],
        textarea {
            margin-bottom: rem-calc(10);
            min-height: rem-calc(40);
            width: 100%;
            display: block;
            flex: none;
        }

        #terms-field {
            span, span > button {
                color: $white;
            }
            span > button {
                text-decoration: underline;
            }
            a {
                color: $white;
                text-decoration: underline;
            }
        }

    }

    @include breakpoint(small) {
        padding-left: 0;
    }
    @include breakpoint(large) {
        
    }

}
