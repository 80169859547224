footer {
  background: #122849;
  margin-top: rem-calc(125);
  position: absolute;
  //bottom: 0;
  // margin: auto;
  width: 100%;
  .bottom {
      border-top: 8px solid #46c3d2;
      padding: rem-calc(20 35);
      .wrapper-right,
       .wrapper-left {
          color: $white;
          button {
              display: inline-block;
              color: $white;
              text-decoration: underline;
          }
          a {
              display: inline-block;
              color: #fff;
              text-decoration: underline;
          }
       }
      .wrapper-right {
        float: right;
      }
      .wrapper-left {
        float: left;
      }
  }
}
