.sites-button {
  background: $primary-color;
  font-size: 1.25rem;
  font-weight: bold;
  box-shadow: 0 -2px 0 rgba(0,0,0,0.2) inset;
  border-radius: 3px;
  border: none;
}

$alert-box-opacity: 0.2;

.alert-box {
  padding-right: 3.25rem;
  width: 100%;

  &.callout {
    color: $white;
    font-weight: bold;
  }

  &.alert {
    border: 3px solid $alert-color;
    border-radius: 5px;
    background-color:rgba($alert-color, $alert-box-opacity);
  }

  &.warning {
    border: 3px solid $warning-color;
    border-radius: 5px;
    background-color:rgba($warning-color, $alert-box-opacity);
  }

  &.success {
    border: 3px solid $success-color;
    border-radius: 5px;
    background-color:rgba($success-color, $alert-box-opacity);
  }

  &.info {
    border: 3px solid $primary-color;
    border-radius: 5px;
    background-color:rgba($primary-color, $alert-box-opacity);
  }

  i {
    border: 2px solid $white;
    border-radius: 50%;
    padding: .3em .325em;
    margin-right: 0.5rem;
  }

  .close-button {
    color: rgba($white, 0.5);
    font-size: 2rem;
    @include vertical-center;
    right: 1rem;
    font-weight: bold;
  }
}
