.fullwidth-header-image,
.fullwidth-content-image {
    img {
        width: 100%;
        display: block;
        margin: auto auto rem-calc(30);
        max-width: rem-calc(425);
    }
}

header {
    background: #122849;
    .top {
        border-bottom: 8px solid #46c3d2;
        .wrapper {
            float: right;
            a, .icon {
                display: inline-block;
                color: $white;
            }
            a {
                margin-right: rem-calc(10);
            }
            .icon {
                padding: rem-calc(15);
                background-color: #004987;
            }
        }
    }
    .landing-header-info {
        display: table;
        width: 100%;
        .container  {
            .phone,
            .social-media {
                display: inline-block;
                vertical-align: middle;
                float: right;
            }
            .phone {
                padding: rem-calc(5) 0;
                font-size: rem-calc(24);
                margin-right: rem-calc(15);
                a {
                    color: $white;
                }
            }
            .social-media {
                margin-top: rem-calc(5);
                a {
                    display: inline-block;
                    img {
                        display: inline-block;
                        vertical-align: middle;
                        max-width: rem-calc(25);
                    }
                }
            }
            @include breakpoint(small) {
                display: table;
                clear: both;
                margin: rem-calc(20) auto;
            }
            @include breakpoint(medium) {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
    .landing-header-logo {
        img {
            display: block;
            max-width: rem-calc(450);
            width: 100%;
            padding: rem-calc(15) 0;
            @include breakpoint(small) {
                margin: auto;
            }
            @include breakpoint(medium) {
                margin: 0;
            }
        }
    }
    .lgbjkyftuyfy {
        @include breakpoint(small) {
            display: block;
        }
        @include breakpoint(medium) {
            display: none;
        }
    }
    .banner-desktop {
        @include breakpoint(small) {
            display: none;
        }
        @include breakpoint(medium) {
            display: none;
        }
        @include breakpoint(large) {
            display: block;
        }
    }
    .banner-tablet {
        @include breakpoint(small) {
            display: block;
        }
        @include breakpoint(large) {
            display: none;
        }
    }
}