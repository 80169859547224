#main {
  margin: rem-calc(50) auto;
  h1, h2, h3, h4, h5, h6 {
    font-weight: $global-weight-normal;
  }
  h1 {
    line-height: 1.2;
    text-align: center;
    font-size: rem-calc(45);
    font-weight: $global-weight-thin;
    color: #46c3d2;
  }
  h2 {
    color: $dark-gray;
    line-height: 1.2;
    + strong {
      color: $primary-color;
      font-style: italic;
      font-weight: $global-weight-bold;
      font-size: rem-calc(18);
      margin-bottom: rem-calc(15);
      display: block;
    }
  }
  .landing-call-to-action {
    margin-bottom: rem-calc(25);
    display: block;
    max-width: rem-calc(325);
  }
  .landing-fact {
    max-width: 100%;
  }
}

.go-back {
  font-size: rem-calc(36);
  display: block;
  text-align: center;
  color: #122849;
  text-decoration: underline;
}

#rebate-content {
  max-width: rem-calc(500);
  margin: auto;
  p {
    max-width: rem-calc(425);
    text-align: center;
    margin: rem-calc(20) auto rem-calc(35);
  }
}

#improving-resiliency {
  padding: rem-calc(50) 0;
  background: $secondary-color;
  h3, p, strong {
    color: $white;
  }
  h3 {
    font-weight: $global-weight-bold;
  }
  strong {
    font-style: italic;
    margin-bottom: rem-calc(15);
    display: block;
  }
  .large-8 {
    padding-right: rem-calc(50);
  }
  &.tougher {
    strong {
      margin-bottom: rem-calc(5);
      font-size: rem-calc(20);
    }
    li {
      color: $white;
    }
  }
  .large-4 {
    img {
      display: block;
      max-width: rem-calc(350);
      width: 100%;
      @include breakpoint(small) {
          margin-top: rem-calc(15);
      }
      @include breakpoint(large) {
          margin-top: rem-calc(75);
      }
    }
  }
}

#serving-florida {
  padding: rem-calc(50) 0;
  background: $white;
  h3 {
    font-weight: $global-weight-bold;
    color: $dark-gray;
    margin-bottom: 0;
  }

  strong {
    color: $primary-color;
    display: block;
    margin-bottom: rem-calc(15);
    font-style: italic;
  }
  .large-8 {
    @include breakpoint(small) {
      padding-right: rem-calc(20);
      padding-left: rem-calc(20);
    }
    @include breakpoint(medium) {
      padding-right: rem-calc(50);
    }
  }
  .large-4 {
    @include breakpoint(small) {
      padding-right: rem-calc(20);
      padding-left: rem-calc(20);
    }
    @include breakpoint(medium) {
      padding-right: rem-calc(20);
      padding-left: rem-calc(20);
    }
    img {
      display: block;
      max-width: rem-calc(350);
      width: 100%;
      @include breakpoint(small) {
          margin-top: rem-calc(15);
      }
      @include breakpoint(large) {
          margin-top: rem-calc(70);
      }
    }
  }
}

#full-the-discussion-callout {
  background: $primary-color;
  padding: rem-calc(50) 0;
  color: $white;
  text-align: center;
  h4 {
    color: $white;
    font-size: rem-calc(26);
    font-weight: $global-weight-bold;
  }
  p {
    margin-bottom: rem-calc(30);
    font-weight: $global-weight-bold;
  }
  img {
    width: 100%;
    max-width: rem-calc(350);
    margin: auto;
    display: block;
  }
}

#architect {
  #main {
    .columns {
      strong {
        color: #0299d2;
        font-style: italic;
        font-size: rem-calc(20);
      }
    }
  }
  &.builder {
    .explore-button {
      max-width: rem-calc(300);
      margin: auto;
    }
    #serving-florida {
      .large-4 {
        a {
          display: block;
          margin-top: rem-calc(40);
          img {
            max-width: rem-calc(250);
            margin: auto;
          }
        }
        p {
          line-height: 1.3;
          strong {
            color: $black;
            display: inline-block;
            font-size: rem-calc(16);
            margin: auto;
          }
        }
        strong {
          font-style: normal;
          font-size: rem-calc(24);
          margin: rem-calc(35) auto rem-calc(10);
          span {
            color: #02619d;
            font-weight: $global-weight-bold;
          }
        }
      }
    }
    #improving-resiliency {
      .large-4 {
        img {
          @include breakpoint(small) {
              margin-top: rem-calc(15);
          }
          @include breakpoint(large) {
              margin-top: rem-calc(125);
          }
        }
      }
    }
  }
}

#owner-type-field {
  div {
    display: inline-block;
    label, input {
      display: inline-block !important;
      width: auto;
    }
    label {
      margin: rem-calc(0 10 0 5);
      line-height: 1;
    }
  }
}

#contact-sidebar {
  // margin-bottom: rem-calc(125);
}


